export const VEHICLE = {
  vehicleDetailsList: "v1/vehicle/details/filters/list", // Send fleetId params,
  vehicleDetailsListWithoutFilters: "v1/vehicle/details/list", // Send fleetId params,
  customColumnValue: "",
  connectorList: "v1/vehicle/connector-types",
  addVehicle: "v1/vehicle/add",
  editVehicleData: "v1/vehicle/edit",
  deleteVehicleDoc: "v1/documents/delete/vehicle",
  getVehicleDoc: "v1/documents/fetch/vehicle",
  bulkUploadVehicleDocs: "v1/documents/upload/bulk/vehicle",
  fetchVehicleDocs: "v1/documents/fetch/vehicle",
  replaceFileHandler: "v1/documents/update/vehicle",
  uploadFileHandler: "v1/documents/upload/vehicle",
  removeConnector: "v1/vehicle/remove/connector",
  vehicleCityList: "v1/vehicle/cities/list",
  customFieldAdd: "v1/vehicle/custom-column/fields/add",
  customFieldUpdate: "v1/vehicle/custom-column/fields/update",
  deleteCustomField: "v1/vehicle/custom-column/delete",
  deleteVehicle: "v1/vehicle/delete/vehicle",
  getCustomFields: "v1/vehicle/custom-column/fields/all",
  bulkRemoveVehicle: "v1/vehicle/bulk-remove",
  bulkEdit: "v1/vehicle/bulk-edit",
  bulkUpload: "v1/vehicle/bulk-upload",
  downloadCsv: "v1/reports/download/vehicle/csv",
  downloadPdf: "v1/reports/download/vehicle/pdf",
  downloadXls: "v1/vehicle/download/list/xls",
  setStatus: "v1/vehicle/edit/status",
};

export const VEHICLE_MODEL = {
  variantList: "v1/vehicle-model/variant/list",
  modelList: "v1/vehicle-model/model/list",
  manufacturerList: "v1/vehicle-model/manufacturer/list",
};

export const GEO_LOCATION = {
  stateList: "v1/geo-location/state/list",
  countryList: "v1/geo-location/country/list",
  cityList: "v1/geo-location/city/list",
};

export const FILE = {
  imageUploadURL: "v1/file/upload/docs",
};

export const USER = {
  updateColumnPreference: "v1/user/update/column-preference",
  getColumnPreference: "v1/user/get/column-preference",
  getProfileData: "v1/user/profile",
  updateProfilePicture: "v1/user/update/image",
};

export const VISITS = {
  downloadCsvOngoing: "v1/reports/download/transactions/ongoing/csv",
  downloadCsvCompleted: "v1/reports/download/transactions/completed/csv",
  downloadPdf: "v1/reports/download/visits/pdf",
  downloadXls: "v1/vehicle/download/list/xls",
};

export const CHARGING_STATION = {
  getVehicleOngoingTrans: "v1/visits/details/ongoing",
  getVehicleCompletedTrans: "v1/visits/details/completed",
  getStationTypes: "v1/station/types",
  getStationRegion: "v1/station/region",
  getStationNames: "v1/station/names",
  getStationStatus: "v1/station/status/list",
  getEntryAttachments: "v1/visits/vehicle/attachments/entry",
  getExitAttachments: "v1/visits/vehicle/attachments/exit",
  getStationsFromLatLng: "v1/station/all",
  markAsFav: "v1/station/set/favorite",
  downloadCsv: "v1/reports/download/station/csv",
  downloadPdf: "v1/reports/download/station/pdf",
  downloadXls: "v1/vehicle/download/list/xls",
  getStationNamesByQuery: "v1/station/matching/list",
  getStationCityList: "v1/station/region",
};

export const DRIVER = {
  getDriverList: "v1/driver/details/filters/list",
  addDriver: "v1/driver/add",
  deleteDriver: "v1/driver/delete",
  deleteColumn: "v1/driver/custom-column/delete",
  getCities: "v1/driver/cities/list",
  getCountries: "v1/driver/country/list",
  getState: "v1/driver/states/list",
  editBulk: "v1/driver/edit/bulk",
  fetchDocuments: "v1/documents/fetch/driver",
  updateDocuments: "v1/documents/update/driver",
  uploadDocuments: "v1/documents/upload/driver",
  bulkUploadDriver: "v1/documents/upload/bulk/driver",
  deleteDocument: "v1/documents/delete/driver",
  getCustomFields: "v1/driver/custom-column/fields/all",
  updateDriver: "v1/driver/edit",
  getStatus: "v1/status",
  editCustomColumn: "v1/driver/custom-column/fields/update",
  deleteCustomColumn: "v1/driver/custom-column/delete",
  customColumnFieldAdd: "v1/driver/custom-column/fields/add",
  bulkRemoveDriver: "v1/driver/bulk-remove",
  downloadCsv: "v1/reports/download/driver/csv",
  downloadPdf: "v1/reports/download/driver/pdf",
  downloadXls: "v1/vehicle/download/list/xls",
  createNewUUid: "v1/driver/uid/new",
  setStatus: "v1/driver/edit/status",
  bulkUpload: "v1/driver/bulk-upload",
  regenerateDriver: "v1/driver/bulk-regenerate",
};

export const TRANSACTIONS = {
  getOngoingTransactions: "v1/charging-transactions/ongoing",
  getCompletedTransactions: "v1/charging-transactions/completed",
  getOngoingTransactionsCount: "v1/charging-transactions/all/ongoing/count",
  getCompletedTransactionsCount: "v1/charging-transactions/all/completed/count",
  getUpcomingTransactions: "v1/charging-transactions/upcoming",
  getAllOngoingTransactions: "v1/charging-transactions/all/ongoing",
  getAllCompletedTransactions: "v1/charging-transactions/all/completed",
};

export const ACCESS = {
  VERIFY_PASSWORD: "v1/auth/verify-password",
  CHECK_TOKEN: "v1/auth/check-token",
  LOGOUT: "v1/auth/logout",
  GOOGLE_LOGIN: "v1/auth/login/google",
};

export const PAYMENTS = {
  paymentsList: "v1/payments/details/filters/list",
  paymentsCount: "v1/payments/details/filters/list/count",
  pageInfo: "v1/payments/page/info",
  downloadCsv: "v1/reports/download/payments/csv",
  downloadPdf: "v1/reports/download/payments/pdf",
  downloadXls: "v1/vehicle/download/list/xls",
  getSpent: "v1/payments/spent/",
  transactionTypes: "v1/payments/transaction-type/status/list",
};

export const GOOGLE = {
  places: "https://maps.googleapis.com/maps/api/place/details/json",
};

export const WALLLET = {
  getWalletInfo: "v1/fleet/wallet/info",
};

export const INVOICES = {
  getInvoicesReportForYear: "v1/invoice/report/year",
  getInvoicesReportForMonth: "v1/invoice/report/month",
};
